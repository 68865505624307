<template lang="pug">
XyzTransition(appear, xyz="fade")
  .card-2
    .box-alert
      .icon
        BaseCustomIcon(nameIcon="mdiCheckBold", :size="48")
      .content
        .title {{ $t('paymentPage.account_created') }}
        p {{ $t('paymentPage.account_created_message') }}
</template>

<script>
export default {
  name: "account-created-component",
};
</script>

<style lang="scss" scoped>
.card-2 {
  @apply rounded-md py-4 pr-4 pl-6 w-full shadow-md relative overflow-hidden border-2 border-green-500 bg-green-50 md:py-8 md:pr-8 md:pl-12;
  .title {
    @apply text-green-800 font-bold text-base md:text-lg;
  }
  p {
    @apply py-2 text-xs font-medium text-green-600 md:text-sm;
  }

  .box-alert {
    @apply flex items-center;
  }

  .icon {
    @apply bg-green-500 text-white rounded-full p-2 mr-4;
  }
}
</style>
<template lang="pug">
BaseCard
  XyzTransition(xyz="fade")
    .div-loading(v-if="loading")
      .bg-loader
      .loader
        .animate-spin.text-blue-400
          BaseCustomIcon(nameIcon="mdiDotsCircle", :size="48")
  BaseCardTitle.payment-title.pr-24 {{ $t('paymentPage.payment_title') }}
  .wrapper-price
    .label {{ $t('paymentPage.payment_trial') }}
    .price {{ trialPrice }}
  PaycometComponent(
    v-on:isLoading="setLoading",
    v-on:error="errorPayment",
    v-on:paymentSucceded="paymentSucceded",
    :forceError="forceError",
  )

</template>

<script>

import PaycometComponent from "@/components/paycomet/component-iframe.vue";
import DownloadFile from "@/composables/download-file";
export default {
  name: "form-payment-component",
  props: {
    trialPrice: {
      type: String,
      default: null
    }
  },
  data: () => ({
    loading: true,
    forceError: false,
  }),
  components: {
    PaycometComponent,
  },
  mounted() {
    const errorForced =
      this.$store.getters["WebOptionsStore/forceErrorPayment"];
    if (errorForced) this.errorPayment();
  },
  beforeUnmount() {
    this.$store.commit('WebOptionsStore/disableErrorPayment')
  },
  methods: {
    setLoading(isLoading) {
      this.loading = isLoading;
    },
    errorPayment() {
      this.loading = true;
      this.forceError = true;
    },
    paymentSucceded() {
      this.$ownGtagAds.conversion({
        send_to: "AW-10837201046/KkFACJ65qZADEJahyq8o",
      });
      this.$gtag.event("trial", {
        event_category: "conversion",
      });
      const fileBase64 = this.$store.getters["DataCvViewerStore/cvParsed"];
      if (fileBase64) {
        DownloadFile().downloadFile(fileBase64);
      }
      this.$router.replace({ name: "my-cvs" });
    },
  }
};
</script>
<style lang="scss" scoped>
.wrapper-price {
  @apply absolute right-8 top-0 bg-indigo-600 text-white px-4 pt-2 pb-2 rounded-b-lg;
  .label {
    @apply text-xs;
  }
  .price {
    @apply text-2xl font-bold;
  }
}

.div-loading {
  @apply absolute w-full h-full top-0 left-0 z-10;

  .bg-loader {
    @apply bg-white opacity-60 absolute w-full h-full;
  }

  .loader {
    @apply flex items-center justify-center h-full w-full;
  }
}

.payment-title {
  @apply mt-12 pr-0 sm:pr-24 sm:mt-0;
}
</style>


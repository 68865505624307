<template lang="pug">
iframe.iframe-paycomet(:src="`/pay?force_error=${forceError}`" v-on:load="$emit('isLoading', false)" ref="iframe_paycomet")
</template>

<script>
export default {
  name: 'component-iframe',
  emits: ["paymentSucceded", "isLoading", "error"],
  props: {
    forceError: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.$emit('isLoading', true)
    window.addEventListener('message', this.receiveMessage)
  },
  beforeUnmount() {
    window.removeEventListener('message', this.receiveMessage)
  },
  methods: {
    receiveMessage({ data }) {
      if (data?.messageType) {
        switch (data.messageType) {
          case 'loading': return this.$emit('isLoading', data.isLoading)
          case 'paymentSucceded': return this.$emit('paymentSucceded')
          case 'error': {
            this.setIsLoading(true);
            this.$emit('error')
            this.$refs.iframe_paycomet.contentWindow.location = '/pay?force_error=true'
            break;
          }
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.iframe-paycomet {
  height: 350px;
  @apply w-full;
}
</style>

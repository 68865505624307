<template lang="pug">
baseInputSelect(
  :listOptions="languages_template",
  v-model="selectedLanguage",
  position="top"
)
</template>

<script>
export default {
  name: "selectorLanguage",
  computed: {
    languages_template() {
      return this.$tm("languages");
    },
    selectedLanguage: {
      get() {
        return this.languages_template.find(
          (l) => l.id == this.$store.getters['UserStore/getLanguange']
        );
      },
      set(lang) {
        this.$store.dispatch('UserStore/setUserLanguage', lang.id)
      }
    }
  },
};
</script>

<style lang="scss" scoped></style>

<template lang="pug">
.footer
  .container.pb-0
    .footer-wrapper
      .col-1
        .box-logo
          baseLogoWeb
        .box-languages
          selectorLanguage
      .col-2
        .box-links
          .text-xl.font-bold.text-yellow-400 {{ $t('footerLinks.title_account') }}
          ul.text-sm
            li
              router-link(:to="{ name: 'my-cvs' }") {{ $t('footerLinks.link_my_cv') }}
            li 
              router-link(:to="{ name: 'builder/select-template' }") {{ $t('footerLinks.link_templates') }}
            li 
              router-link(:to="{ name: 'my-cvs' }") {{ $t('footerLinks.link_my_account') }}
        .box-links
          .text-xl.font-bold.text-green-500 {{ $t('footerLinks.title_pages') }}
          ul.text-sm
            li
              router-link(:to="{ name: 'Faq' }") {{ $t('footerLinks.link_faq') }}
            li 
              router-link(:to="{ name: 'Contacto' }") {{ $t('footerLinks.link_contact') }}
            li.cancel
              router-link(:to="{ name: 'Cancelar' }") {{ $t('footerLinks.link_cancel') }}
            li 
              router-link(:to="{ name: 'Precios' }") {{ $t('footerLinks.link_prices') }}
        .box-links
          .text-xl.font-bold.text-blue-500 {{ $t('footerLinks.title_info') }}
          ul.text-sm
            li
              router-link(:to="{ name: 'Cookies' }") {{ $t('footerLinks.link_cookies') }}
            li 
              router-link(:to="{ name: 'Privacidad' }") {{ $t('footerLinks.link_privacy') }}
            li 
              router-link(:to="{ name: 'Terminos' }") {{ $t('footerLinks.link_conditions') }}
    .w-full.h-px.bg-gray-200.mt-16
    .text-sm.text-left.py-2.text-gray-400.flex.flex-col 
      p.text-xs {{ $t('appNamedFiscal') }}
      p.text-xs {{ $t('appNamedIdFiscal') }}
      p.text-xs {{ $t('appNamedMail') }}
      p.text-xs {{ $t('appNamedAddressFiscal') }}
      p.text-right © {{ $t('appNamed') }} - {{ new Date().getFullYear() }}
.w-full.h-2.bg-green-400
</template>
<script>
import selectorLanguage from "@/components/molecules/selectorLanguage";
export default {
  name: "navFooter",
  components: {
    selectorLanguage,
  },
};
</script>
<style lang="scss" scoped>
ul li {
  @apply mt-2;
  a {
    @apply text-gray-500 transition-colors;
  }
  a:hover {
    @apply text-blue-700;
  }

  &.cancel a:hover {
    @apply text-red-500;
  }
}

.footer {
  @apply w-full bg-gray-50 pt-8 border-t border-gray-100 mt-auto md:pt-20;
}
.footer-wrapper {
  @apply grid grid-cols-1 gap-5 md:grid-cols-5;
}

.col-1 {
  @apply flex flex-col items-center md:col-span-2 md:items-start;

  .box-logo {
    @apply mb-6 self-start md:mb-auto;
  }
  .box-languages {
    @apply w-full md:max-w-xs;
  }
}

.col-2 {
  @apply flex flex-col gap-6 pt-8 sm:flex-row sm:justify-between md:col-span-3 md:pt-0;
  .box-links {
    @apply flex flex-col flex-grow;
  }
}
</style>